.Skills{
    padding: 0 0 0 0;
    display: flex;
    flex-direction: column;
    height: 15vh;
    margin-bottom: 9rem;
    margin-top: 9rem;
}
.Skills span{
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.Skills>:nth-child(2){
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: 2rem;
    gap:2rem;
    width: 8rem;
}

.skillList>*{
    width: 5rem;
    height: 5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: space-evenly;
}

.skillList>*:hover{
    transform: scale(1.01);
    box-shadow: 0 0 500px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 360px){
    .Skills {
        margin-left: 6rem;
    }
    .skillList{
        flex-direction:column;
        display: flex;
        gap: 1rem;
        margin-bottom: 2rem;
        
    }
    
    
}