  /* Add this to your Projects.css file */

  .experience{
    padding: 0 3rem 0 3rem;
    display: flex;
    flex-direction: column;
    height: 90 vh;
    margin-bottom: 8rem;
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .Head span{
      font-weight: bold;
      font-size: 2.5rem;
  }

  .projectlist ul {
      display: flex;
      justify-content: space-evenly; /* Adjust as needed, e.g., space-around, space-evenly */
      padding: 0 3rem 0 3rem;
      margin-bottom: 13rem;
    }
    
    .projectlist li {
      flex: 0 0 auto;
      margin-right: 15rem; /* Adjust as needed to add space between items */
      padding: 0 2rem 0 2rem;
      margin-bottom: 20rem;
    }
    
    @media screen and (max-width: 360px) {
      .projectlist ul{
        display: flex;
        flex-direction: column;
        margin-bottom: 13rem;
        padding: 0.5rem 1rem;
        height: 55rem;
        padding: 0;
        gap: 5rem;
        
      }
      .projectlist{
        margin-top: 22rem;
      }
      .Head span{
        margin-bottom: 20rem;
      }
      .Head{
        margin-top: 60rem;
      }
      
      
    }