.intro{
    display: flex;
    height: 77vh;
    margin-top: 6rem;
}

.i-left{
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    gap: 2rem; /*gives a gap to every element*/
}

.i-name{
    display: flex;
    flex-direction: column;
}

.i-name>:nth-child(1)
{
    color: var(--black);
    font-weight: bold;
    font-size: 3rem;
}

.i-name>:nth-child(2)
{
    color: var(--orange);
    font-weight: bold;
    font-size: 3rem;
}

.i-name>:nth-child(3)
{
    font-weight: 100;
    font-size: 30px;
    color: var(--gray);
    margin-top: 10px;
}

.i-button{
    width: 6rem;
    height: 2rem;
}

.i-icons{
    margin-top: 3rem;
    display: flex;
    gap: 0rem;
}

.i-icons>*{
    transform: scale(0.5)
}

.i-icons>*:hover{
    cursor: pointer;
}

.i-right{
    flex: 1;
    position: relatvie;
}
.i-right>*{
    position: absolute;
    z-index: 1;
}

.i-right>:nth-child(1)
{
    transform: scale(0.69);
    height: 50%;
    right: 15%;
    top: 8rem;
}

@media screen and (max-width: 360px) {
    .intro{
        flex-direction: column;
        height: 64rem;
        gap: 7rem;
    }
    .i-right{
        transform: scale(0.8);
        left: -3rem
    }
    .floating-div:nth-of-type(1){
        top: -7rem !important
    }
    .i-right>:nth-child(1){
        transform: scale(1.5);
        bottom: 30rem;
    }
  }