.AboutMe{
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 90 vh;
    margin-bottom: 8rem;
    margin-top: 9rem;
    margin-bottom: 20rem;
}

.about{
    display: flex;
    flex-direction: column;
    position: relative;
}

.about > :nth-child(1){
    color: var(--black);
    font-size: 2.5rem;
    font-weight: bold;
}

.about > :nth-child(2){
    color: var(--gray);
    font-size: 20px;
    margin-top: 1rem;
}

.s-button{
    width: 8rem;
    height: 2rem;
    margin-top: 1rem;
}

.cards{
    position: relative;
}
/*everything inside the card is absolute*/
.cards>*{
    position: absolute;
}

@media screen and (max-width: 360px) {
    .AboutMe{
      margin-top: 0;
      flex-direction: column;
      gap: 5rem;
      height: 66rem;
      padding: 0;
      margin-bottom: 3%;
    }
    .cards{
        display: flex;
        flex-direction: column;
        gap:5rem;
        transform: scale(.5);
        bottom: 20rem;
    }
    .cards>*{
        
        position: static;

    }
  }