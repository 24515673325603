.head>* {
    color: var(--black);
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
}

.experience{
    padding: 0 3rem 0 3rem;
    display: flex;
    flex-direction: column;
    height: 90;
    margin-bottom: 13rem;
    margin-top: 9rem;
    margin-bottom: 13rem;
}

@media screen and (max-width: 360px){
    
    .experience{
        margin-top: 20rem;
    }
}