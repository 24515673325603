.projectcard{
    width: 10rem;
    height: 20rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 10rem;
    text-align: center;
    background: rgba(255, 255, 255, 0.26);
    border: 7px solid var(--orangeCard);
    box-shadow: var(--boxShadow);
    border-radius: 20px;
    padding: 0px 26px 2rem 26px;
}

.projectcard:hover{
    transform: scale(1.01); 
    box-shadow: 0 0 500px rgba(0, 0, 0, 0.2);
}

.title span{
    font-weight: bold;
    font-size: large;
}

